import React, { useState } from "react";
import axios from "axios";
import PlacesAutocomplete, {
  geocodeByAddress
} from "react-places-autocomplete";

var ref;
var bgURL;
var divStyle;
var fullAddress;

export default function App() {
  const [data, setData] = useState({});
  const [data2, setData2] = useState({});
  const [address, setAddress] = useState("");

  const handleSelect = async value => {
    const results = await geocodeByAddress(value);
    fullAddress = results[0]["formatted_address"];
    console.log(fullAddress);
    const url = `https://api.openweathermap.org/data/2.5/weather?q=${fullAddress}&units=imperial&appid=${process.env.REACT_APP_OPENWEATHER_API_KEY}`;
    //const url2 = `https://young-beach-04634.herokuapp.com/https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${fullAddress}&key=${process.env.REACT_APP_GOOGLE_API_KEY}&inputtype=textquery&fields=name,photos`;
    const url2 = `http://localhost:8080/https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${fullAddress}&key=${process.env.REACT_APP_GOOGLE_API_KEY}&inputtype=textquery&fields=name,photos`;
    axios.get(url).then((response) => {
      setData(response.data);
      console.log(response.data);
    });
    axios.get(url2).then((response) => {
      setData2(response.data);
      console.log(response.data);
    });

  };
  /*const searchLocation = (event) => {
    if (event.key === "Enter") {
      axios.get(url).then((response) => {
        setData(response.data);
        console.log(response.data);
      });

      axios.get(url2).then((response) => {
        setData2(response.data);
        console.log(response.data);
      });

      setLocation("");
    }
  };*/
  ref = data2?.candidates?.[0].photos?.[0]?.photo_reference;
  bgURL = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=1600&photoreference=${ref}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  divStyle = {
    backgroundImage: "url(" + bgURL + ")",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  return (
    <div className="app">
      <div style={divStyle}>
        <div className="title">
          OpenWeather React App
        </div>
        <div className="search">
          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
          >
            {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
            <div>
              <input {...getInputProps({placeholder: "Start typing location..."})}/>

              <div>
                {loading ? <div>...loading</div> : null}

                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active ? "rgba(100, 100, 100, 0.75)" : "rgba(0, 0, 0, 0.5)"
                  };
                  return (
                    <div {...getSuggestionItemProps(suggestion, {style})}>
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
            )}
          </PlacesAutocomplete>
          {/* <input
            value={location}
            onChange={(event) => setLocation(event.target.value)}
            onKeyPress={searchLocation}
            placeholder="Enter Location"
            type="text"
          /> */}
        </div>
        <div className="container">
          <div className="top">
            <div className="location">
              {data.sys ? (
                <p>
                  {data.name}, {data.sys.country}
                </p>
              ) : null}
            </div>
            <div className="temp">
              {data.main ? <h1>{data.main.temp.toFixed()} °F</h1> : null}
            </div>
            <div className="description">
              {data.weather ? (
                <p className="bold">{data.weather[0].main}</p>
              ) : null}
            </div>
          </div>

          {data.name !== undefined && (
            <div className="bottom">
              <div className="feels">
                {data.main ? (
                  <p className="bold">{data.main.feels_like.toFixed()} °F</p>
                ) : null}
                <p>Feels Like</p>
              </div>
              <div className="humidity">
                {data.main ? (
                  <p className="bold">{data.main.humidity.toFixed()} %</p>
                ) : null}
                <p>Humidity</p>
              </div>
              <div className="wind">
                {data.wind ? (
                  <p className="bold">{data.wind.speed} MPH</p>
                ) : null}
                <p>Wind Speed</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
